import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Container, Paper, InputAdornment, Alert } from '@mui/material';
import { AccountCircle, Lock } from '@mui/icons-material';
import './Login.css';  // CSS para el background y logo

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); 
    setSuccess(false); 
    
    try {
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      if (response.ok) {
        setSuccess(true);
        window.location.href = data.redirect;
      } else {
        setError(data.message || 'Credenciales incorrectas');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Error al conectarse con el servidor.');
    }
  };

  return (
    <div className="login-container">
      <div className="login-background">
        <div className="login-logo">
          <img src="/images/2.svg" alt="Logo" />
        </div>
        <Container component="main" maxWidth="xs">
          <Paper elevation={6} sx={{ padding: { xs: 2, sm: 3, md: 4 }, borderRadius: '10px', backgroundColor: '#f5f5f5' }}>
            <Typography component="h1" variant="h5" align="center" sx={{ marginBottom: 2, color: '#4CAF50', fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2rem' } }}>
              Bienvenido
            </Typography>
            <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
              <TextField margin="normal" fullWidth label="Username" variant="outlined" value={username} onChange={(e) => setUsername(e.target.value)} required
                InputProps={{ startAdornment: <InputAdornment position="start"><AccountCircle /></InputAdornment> }} sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }} />
              <TextField margin="normal" fullWidth label="Password" type="password" variant="outlined" value={password} onChange={(e) => setPassword(e.target.value)} required
                InputProps={{ startAdornment: <InputAdornment position="start"><Lock /></InputAdornment> }} sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }} />
              {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
              {success && <Alert severity="success" sx={{ mt: 2 }}>Login exitoso</Alert>}
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, backgroundColor: '#4CAF50', '&:hover': { backgroundColor: '#388E3C' }, fontSize: { xs: '0.8rem', sm: '1rem' }, padding: { xs: '10px 0', sm: '15px 0' } }}>
                LOGIN
              </Button>
            </Box>
          </Paper>
        </Container>
      </div>
    </div>
  );
}

export default Login;
